import React, { useEffect } from "react";
import TopNav from "../components/TopNav/TopNav";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import jwt_decode from "jwt-decode";
import { useDispatch } from "react-redux";
import Cookies from "js-cookie";
import "../css/custom.css";
import "../css/LoadingBars.css";
import { GlobalStore } from "../components/GlobalStore";
import WaveformAnalysisTab from "../components/tabs/WaveformAnalysisTab";
import TrendingAnalysisTab from "../components/tabs/TrendingAnalysis/TrendingAnalysis";
import EventsAnalysisTab from "../components/tabs/EventsAnalysisTab";
//import EventsAnalysisTab from '../components/tabs/EventsAnalysis/EventsAnalysis';
import HarmonicAnalysisTab from "../components/tabs/HarmonicAnalysisTab";
import NotificationSettings from "../components/tabs/NotificationSettings";
import OverviewTab from "../components/tabs/Overview/Overview";
import { equipmentsPortfolioActions } from "../store/equipmentsPortfolioSlice/equipmentPortfolioSlice";
import { tabStatusActions } from "../store/tabStatusSlice/tabStatusSlice";
import { userActions } from "../store/userSlice/userSlice";
import { useSelector } from "react-redux";

import CommonAPI from "../api/CommonApi";
import jwtDecode from "jwt-decode";

const { setIntervalAsync } = require("set-interval-async/fixed");

export const TimezoneContext = React.createContext();

export default function Dashboard() {
    const token = Cookies.get("Session");
    const dispatch = useDispatch();

    const customer = useSelector((state) => state.equipmentsPortfolio.customerList[0].label);
    const earlyAccess = useSelector((state) => state.user.earlyAccess);

    const check_jwt_expiry = () => {
        const token = Cookies.get("Session");
        let tokenDuration = new Date().getTime();
        if (token !== undefined) {
            tokenDuration = new Date(jwt_decode(token).exp * 1000).getTime();
        }

        if (new Date().getTime() >= tokenDuration) {
            Cookies.remove("Session");
            Cookies.remove("portalSession");
            window.location = process.env.REACT_APP_PORTAL_URL + "/#/logout/";
        }
    };

    const check_early_access = () => {
        return jwt_decode(token).earlyAccess;
    };

    useEffect(() => {
        setIntervalAsync(check_jwt_expiry, 10000);
        CommonAPI.getCustomerPortfolio(token).then((response) =>
            dispatch(equipmentsPortfolioActions.setEquipmentPortfolio(response.data.content))
        );
        dispatch(userActions.setEarlyAccess(check_early_access()));
    }, []);

    if (!customer) {
        ///-----Insert Loading Page -----///
        return <div />;
    }

    return (
        <div className="body">
            <React.Fragment>
                <Container fluid>
                    <GlobalStore>
                        <Row>
                            <Col>
                                <TopNav />
                            </Col>
                        </Row>
                        <br></br>
                        <Row>
                            <Col>
                                {customer == "Georgia Pacific" ? (
                                    <Tabs
                                        defaultActiveKey="Overview"
                                        id="menu-tabs"
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            dispatch(tabStatusActions.setSelectedTab(tabName));
                                        }}
                                    >
                                        <Tab eventKey="Overview" title="Overview">
                                            <OverviewTab />
                                        </Tab>
                                        <Tab eventKey="Waveform" title="Waveform Analysis">
                                            <WaveformAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Harmonic Analysis" title="Harmonic Analysis">
                                            <HarmonicAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Trending Analysis" title="Trending Analysis">
                                            <TrendingAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Events" title="Events Analysis">
                                            <EventsAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Notifications" title="Notifications Settings">
                                            <NotificationSettings />
                                        </Tab>
                                    </Tabs>
                                ) : (
                                    <Tabs
                                        defaultActiveKey="Waveform"
                                        id="menu-tabs"
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            dispatch(tabStatusActions.setSelectedTab(tabName));
                                        }}
                                    >
                                        <Tab eventKey="Waveform" title="Waveform Analysis">
                                            <WaveformAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Harmonic Analysis" title="Harmonic Analysis">
                                            <HarmonicAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Trending Analysis" title="Trending Analysis">
                                            <TrendingAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Events" title="Events Analysis">
                                            <EventsAnalysisTab />
                                        </Tab>
                                        <Tab eventKey="Notifications" title="Notifications Settings">
                                            <NotificationSettings />
                                        </Tab>
                                    </Tabs>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-5 offset-5">
                                <h6>Version 5.0.1</h6>{" "}
                            </Col>
                        </Row>
                    </GlobalStore>
                </Container>
            </React.Fragment>
        </div>
    );
}
