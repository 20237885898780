import React, { useState, useEffect, Suspense } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import SingleDatePicker from "../DatePickers/SingleDatePicker/SingleDatePicker";

import WaveformAnalysisApi from "../../api/WaveformApi";

import LeftFilterBox from "../LeftFilterBox/LeftFilterBox";

import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
const WaveformAnalysisPlot = React.lazy(() => import("../plots/WaveformAnalysisPlot"));

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export default function WaveformAnalysisTab() {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedFacility, selectedEquipment, equipmentTimezone, showSELMenu } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);

    const [esaList, setEsaList] = useState([]);
    const [selectedEsa, setSelectedEsa] = useState();

    const [selectedDate, setSelectedDate] = useState(new Date());

    const [plotDuration, setPlotDuration] = useState(2);
    const [showPlot, setShowPlot] = useState(false);
    const [isPlotLoading, setIsPlotLoading] = useState(false);

    const [showAlert, setShowAlert] = useState(false);
    const [alertColor, setAlertColor] = useState();
    const [alertText, setAlertText] = useState();
    const [isPlotDurationValid, setIsPlotDurationValid] = useState(false);

    const [plotData, setPlotData] = useState();

    const [waveformPlotMetaData, setWaveformPlotMetaData] = useState({
        equipment_name: "",
        eqTypeSub: "---",
        facility_name: "",
        esa_timestamp: "",
        Np_I_peak: "",
    });

    // useEffect(() => {
    // 	// Api call for Esa list
    // 	if (selectedEquipment) {
    // 		setEsaList([]);
    // 		setSelectedEsa();
    // 		WaveformAnalysisApi.getEsaList(token, selectedEquipment.value, formatDate(selectedDate), timezone.value)
    // 			.then((response) => {
    // 				if (response.status === 204) {
    // 					setAlertColor('danger');
    // 					setAlertText('No ESA Found');
    // 					setShowAlert(true);
    // 				} else {
    // 					setEsaList(response.data.content.list_data);
    // 				}
    // 			})
    // 			.catch((error) => {
    // 				console.log(error);
    // 			});
    // 	}
    // }, [timezone]);

    useEffect(() => {
        if (selectedFacility) {
            setShowAlert(false);
            // Clear Esa Dropdown
            setEsaList([]);
            setSelectedEsa();
        }
    }, [selectedFacility]);

    useEffect(() => {
        if (selectedEquipment) {
            setShowAlert(false);
            // Clear Esa Dropdown
            setEsaList([]);
            setSelectedEsa();

            // Api call for Esa list
            WaveformAnalysisApi.getEsaList(
                token,
                selectedEquipment.value,
                formatDate(selectedDate),
                equipmentTimezone.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setAlertColor("danger");
                        setAlertText("No ESA Found");
                        setShowAlert(true);
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setAlertColor("primary");
                            setAlertText(text);
                            setShowAlert(true);
                        } else {
                            setEsaList(response.data.content.list_data);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [selectedEquipment]);

    useEffect(() => {
        if (showPlot) {
            setWaveformPlotMetaData({ ...waveformPlotMetaData, selectedVoltageType: selectedVoltageType });
        }
    }, [selectedVoltageType]);

    // Function Triggers on select Date
    const onDateChange = (date) => {
        setSelectedDate(date);
        setEsaList([]);
        setSelectedEsa();
        setShowAlert(false);
        if (selectedEquipment) {
            // Api call for Esa list
            WaveformAnalysisApi.getEsaList(
                token,
                selectedEquipment.value,
                formatDate(date),
                equipmentTimezone.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setAlertColor("danger");
                        setAlertText("No ESA Found");
                        setShowAlert(true);
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setAlertColor("primary");
                            setAlertText(text);
                            setShowAlert(true);
                        } else {
                            setEsaList(response.data.content.list_data);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const onPlotDurationChange = (newDuration) => {
        const duration = parseFloat(newDuration);
        setPlotDuration(parseFloat(newDuration));
        if (duration >= 0 && duration <= 40) {
            setIsPlotDurationValid(false);
        } else {
            setIsPlotDurationValid(true);
        }
    };

    const onPlotButtonClick = async () => {
        if (!selectedFacility) {
            setAlertColor("primary");
            setAlertText("Select a Facility");
            setShowAlert(true);
        } else if (!selectedEquipment) {
            setAlertColor("primary");
            setAlertText("Select an Equipment ");
            setShowAlert(true);
        } else if (!selectedEsa) {
            setAlertColor("primary");
            setAlertText("Select a ESA File ");
            setShowAlert(true);
        } else if (!(plotDuration > 0 && plotDuration <= 40)) {
            setAlertColor("primary");
            setAlertText("Enter number of seconds between 0 - 40");
            setShowAlert(true);
        } else {
            setShowPlot(true);
            setShowAlert(false);
            setIsPlotLoading(true);
            setWaveformPlotMetaData({
                selectedEquipment: selectedEquipment,
                selectedESA: selectedEsa,
                selectedVoltageType: selectedVoltageType,
            });
            setPlotData({
                time: [],
                current: {
                    Ia: [],
                    Ib: [],
                    Ic: [],
                },
                voltage_ln: {
                    Va: [],
                    Vb: [],
                    Vc: [],
                },
                voltage_ll: {
                    Vab: [],
                    Vbc: [],
                    Vca: [],
                },
            });

            WaveformAnalysisApi.getWaveformPlotData(
                token,
                selectedEquipment.location_node_id,
                selectedEsa.value,
                plotDuration,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setIsPlotLoading(false);
                        setShowPlot(false);
                        setAlertColor("primary");
                        setAlertText("No Data Found");
                        setShowAlert(true);
                    } else {
                        setPlotData(response.data.content.plot_data);
                        setShowPlot(true);
                        setIsPlotLoading(false);
                    }
                })
                .catch((error) => {
                    setIsPlotLoading(false);
                    setShowPlot(false);
                    console.log(error);
                });
        }
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>
                        {/************************************************* Sub Menu Tabs **********************************************************************/}
                        <Col className="col-8">
                            <Card>
                                <Card.Body>
                                    <Tabs defaultActiveKey="Waveforms" variant="pills">
                                        {/* **************************************** WaveForm Tabs **************************************** */}
                                        <Tab eventKey="Waveforms" title="Waveforms">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Waveforms</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="waveform-date">
                                                    <label>Select Date:</label>
                                                    <br></br>
                                                    <SingleDatePicker
                                                        startDate={selectedDate}
                                                        onDateChange={onDateChange}
                                                    />
                                                </Col>
                                                {!showSELMenu ? (
                                                    <Col>
                                                        <label>Enter number of seconds:</label>
                                                        <br></br>
                                                        <Col className="col-8 margin_left_-15 waveform-sec-ctn">
                                                            <Form.Control
                                                                type="number"
                                                                min={0}
                                                                max={40}
                                                                value={plotDuration}
                                                                onInput={(newDuration) =>
                                                                    onPlotDurationChange(newDuration.target.value)
                                                                }
                                                                isInvalid={isPlotDurationValid}
                                                                required
                                                            />
                                                        </Col>{" "}
                                                    </Col>
                                                ) : null}
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col className="col-6">
                                                    <label>Select ESA File:</label>
                                                    <br></br>
                                                    <Dropdown
                                                        options={esaList}
                                                        value={selectedEsa}
                                                        onChange={(value) => {
                                                            setShowAlert(false);
                                                            setSelectedEsa(value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-2">
                                                    {isPlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onPlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col className="col-5 waveformAlert alert">
                                                    <Alert
                                                        variant={alertColor}
                                                        show={showAlert}
                                                        onClose={() => setShowAlert(false)}
                                                        dismissible
                                                    >
                                                        {alertText}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            {/********************************************** Plot Section ***************************************************************************/}
            <Suspense fallback={<div>Loading..</div>}>
                <WaveformAnalysisPlot
                    plotData={plotData}
                    showPlot={showPlot}
                    setShowPlot={setShowPlot}
                    setPlotData={setPlotData}
                    plotMetaData={waveformPlotMetaData}
                    isPlotLoading={isPlotLoading}
                />
            </Suspense>
        </div>
    );
}
