import React from "react";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { equipmentsPortfolioActions } from "../../../../store/equipmentsPortfolioSlice/equipmentPortfolioSlice";
import TrendingAnalysisAPI from "../../../../api/TrendingAnalysisApi";
import Cookies from "js-cookie";

const SelectEquipment = () => {
    const { equipmentsList, selectedEquipment } = useSelector((state) => state.equipmentsPortfolio);

    const dispatch = useDispatch();

    const portalToken = Cookies.get("portalSession");

    const onSelectEquipment = (equipment) => {
        // Clear Selected list
        dispatch(equipmentsPortfolioActions.setSelectedEquipment(equipment));

        // check node connectivity status
        if (equipment.product_type === "Node") {
            TrendingAnalysisAPI.getPortalRealTimeMetering(portalToken, equipment.location_node_id.split(".")[0])
                .then((response) => {
                    if (equipment.location_node_id.split(".")[1] == "0") {
                        dispatch(equipmentsPortfolioActions.setEquipmentConnStatus(response.data.data.connectedStatus));
                    } else {
                        dispatch(
                            equipmentsPortfolioActions.setEquipmentConnStatus(
                                response.data.data.secondaryConnectedStatus
                            )
                        );
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    return (
        <React.Fragment>
            <Select options={equipmentsList} value={selectedEquipment} onChange={(value) => onSelectEquipment(value)} />
        </React.Fragment>
    );
};

export default SelectEquipment;
