import React, { useEffect, useState } from "react";
import logo from "../volta_insite_logo_2024.png";
import LogoutButton from "./components/LogoutButton";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Dropdown from "react-dropdown";
import { useSelector, useDispatch } from "react-redux";
import { voltageTypeActions } from "../../store/voltageTypeSlice/volatgeTypeSlice";
import VoltageTypeSelect from "./components/VoltageTypeSelect";
import "./TopNav.css";

const voltageTypeOptions = [
    { label: "Line to Line", value: "L-L" },
    { label: "Line to Neutral", value: "L-N" },
];

export default function TopNav(props) {
    const { showVoltageAlert } = useSelector((state) => state.voltageType);

    return (
        <React.Fragment>
            <Row className="align-self-center  my-auto">
                <Col className="col-8">
                    <img src={logo} alt="logo" />
                </Col>
                <Col className="col-1 mt-4 voltageLabel">
                    <label>Voltage: </label>
                </Col>
                <Col className="col-2 mt-4 l-lDropdown">
                    <VoltageTypeSelect />
                    <br></br>
                    <div className="dropdown-alert">
                        <Alert variant={"primary"} show={showVoltageAlert}>
                            {"Feature not available for this Equipment"}
                        </Alert>
                    </div>
                </Col>

                <Col className="logoutbutton-ctn col-1">
                    <LogoutButton />
                </Col>
            </Row>
        </React.Fragment>
    );
}
