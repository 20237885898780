import authAxios from "../axios/authAxios";

class HarmonicAnalysisApi {
    static get_fft_esa_list(accessToken, location_node_id, date, timezone, product_type) {
        return authAxios.get("/v1/common/get_esa_list/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${date}`,
                end_date: `${date}`,
                timezone: `${timezone}`,
                product_type: `${product_type}`,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static getFFTData(
        accessToken,
        location_node_id,
        selectedEsa_value,
        axis_type,
        fft_frequency,
        v_type,
        product_type
    ) {
        return authAxios.get("/v1/harmonic_analysis/get_fft_data/", {
            params: {
                location_node_id: `${location_node_id}`,
                esa_key: `${selectedEsa_value}`,
                xaxis: `${axis_type}`,
                fft_range: `${fft_frequency}`,
                v_type: `${v_type}`,
                product_type: `${product_type}`,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static getHarmonicPlotData(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        harmonic_freq_lf,
        parameter,
        search_type,
        avg_period,
        timezone,
        v_type,
        subplots,
        work_cycle
    ) {
        return authAxios.get("/v1/harmonic_analysis/harmonic_trend/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                search_harmonic: `${harmonic_freq_lf}`,
                parameter: `${parameter}`,
                search_type: `${search_type}`,
                avg_period: `${avg_period}`,
                timezone: `${timezone}`,
                v_type: `${v_type}`,
                subplots: JSON.stringify(subplots),
                work_cyce: work_cycle,
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }

    static getSelHarmonicPlotData(
        accessToken,
        location_node_id,
        startDate,
        endDate,
        harmonic_freq_lf,
        parameter,
        search_type,
        avg_period,
        timezone,
        subplots
    ) {
        return authAxios.get("/v1/harmonic_analysis/sel_harmonic_trend/", {
            params: {
                location_node_id: `${location_node_id}`,
                start_date: `${startDate}`,
                end_date: `${endDate}`,
                search_harmonic: `${harmonic_freq_lf}`,
                parameter: `${parameter}`,
                search_type: `${search_type}`,
                avg_period: `${avg_period}`,
                timezone: `${timezone}`,
                subplots: JSON.stringify(subplots),
            },
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        });
    }
}

export default HarmonicAnalysisApi;
