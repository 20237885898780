import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import Alert from "react-bootstrap/Alert";
import { useSelector, useDispatch } from "react-redux";
import { voltageTypeActions } from "../../../store/voltageTypeSlice/volatgeTypeSlice";
import Dropdown from "react-dropdown";

const voltageTypeOptions = [
    { label: "Line to Line", value: "L-L" },
    { label: "Line to Neutral", value: "L-N" },
];

const VoltageTypeSelect = () => {
    const { selectedVoltageType } = useSelector((state) => state.voltageType);
    const selectedEquipment = useSelector((state) => state.equipmentsPortfolio.selectedEquipment);
    const dispatch = useDispatch();

    const [isDropdownDisabled, setIsDropdownDisabled] = useState(false);

    useEffect(() => {
        if (selectedEquipment) {
            if (
                selectedEquipment.product_type === "SEL" ||
                selectedEquipment.eq_type_sub === "v1" ||
                selectedEquipment.eq_type === "dc" ||
                selectedEquipment.eq_type_sub === "v2"
            ) {
                if (selectedVoltageType.value !== "L-L") {
                    dispatch(voltageTypeActions.setVoltageType({ label: "Line to Line", value: "L-L" }));
                }
                setIsDropdownDisabled(true);
            } else if (selectedEquipment.eq_type_sub === "i1") {
                if (selectedVoltageType.value !== "L-N") {
                    dispatch(voltageTypeActions.setVoltageType({ label: "Line to Neutral", value: "L-N" }));
                }
                setIsDropdownDisabled(true);
            } else {
                setIsDropdownDisabled(false);
            }
        }
    }, [selectedEquipment]);

    return (
        <React.Fragment>
            <Dropdown
                disabled={isDropdownDisabled}
                onFocus={() => {
                    if (isDropdownDisabled) {
                        dispatch(voltageTypeActions.setShowVoltageAlert(true));
                        setTimeout(() => {
                            dispatch(voltageTypeActions.setShowVoltageAlert(false));
                        }, 4000);
                    }
                }}
                options={voltageTypeOptions}
                value={selectedVoltageType}
                onChange={(val) => {
                    dispatch(voltageTypeActions.setVoltageType(val));
                }}
            />
        </React.Fragment>
    );
};

export default VoltageTypeSelect;
