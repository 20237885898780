import React, { useState, useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import SingleDatePicker from "../DatePickers/SingleDatePicker/SingleDatePicker";
import DateRangePicker from "../DatePickers/DateRangePicker/DateRangePicker";

import LeftFilterBox from "../LeftFilterBox/LeftFilterBox";
import HarmonicAnalysisApi from "../../api/HarmonicAnalysisApi";
import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";
import Select from "react-select";

const FFTPlot = lazy(() => import("../plots/FFTPlot"));

const HarmonicAmplitudeTrendingPlot = lazy(() => import("../plots/HarmonicAmplitudeTrendingPlot"));

const plotParametersAC = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    { label: "Line Frequency", value: "Line Frequency" },
    { label: "Power Factor", value: "Power Factor" },
    { label: "Voltage Imbalance", value: "Voltage Imbalance" },
    { label: "Current Imbalance", value: "Current Imbalance" },
    { label: "Voltage THD", value: "Voltage THD" },
    { label: "Current THD", value: "Current THD" },
    {
        label: "V-Peaks",
        value: "V-Peaks",
    },
    { label: "HP", value: "HP" },
];

const plotParametersDC = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    {
        label: "V-Peaks",
        value: "V-Peaks",
    },
    { label: "HP", value: "HP" },
];

const plotParametersUtilityMain = [
    { label: "Power Factor", value: "Raw Power Factor" },
    { label: "Apparent Power (kVA)", value: "Apparent Power (kVA)" },
    { label: "Reactive Power (kVAR)", value: "Reactive Power (kVAR)" },
    { label: "Active Power (kW)", value: "Active Power (kW)" },
];

//Add these parameters for primary DC and v2 nodes to query the field node
const fieldParameters = [
    { label: "Field Voltage", value: "Field Voltage" },
    { label: "Field Current", value: "Field Current" },
];

const plotParametersSEL = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    { label: "Line Frequency", value: "Line Frequency" },
    { label: "Voltage Imbalance", value: "Voltage Imbalance" },
    { label: "Current Imbalance", value: "Current Imbalance" },
    { label: "Max Voltage Imbalance", value: "Max Voltage Cycle Imbalance" },
    { label: "Max Current Imbalance", value: "Max Current Cycle Imbalance" },
    { label: "Power Factor", value: "Power Factor" },
    { label: "Power kW", value: "Power kW" },
];

const plotParametersRelay = [
    { label: "Voltage", value: "Voltage" },
    { label: "Current", value: "Current" },
    { label: "Line Frequency", value: "Line Frequency" },
    { label: "RPM", value: "RPM" },
];

let plotParameters = plotParametersAC;

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

export default function HarmonicAnalysisTab() {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedFacility, selectedEquipment, equipmentTimezone, showSELMenu } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);
    const [activeTabKey, setActiveTabKey] = useState("Plot FFT");

    const [plotOrder, setPlotOrder] = useState({
        fft: 1,
        harmonic: 2,
    });

    // FFT Tab state hooks
    const [fftEsaList, setFFTEsaList] = useState([]);
    const [selectedFFTEsa, setselectedFFTEsa] = useState();

    const [selectedFFTDate, setSelectedFFTDate] = useState(new Date());
    const [fftPlotAxisType, setFFTPlotAxisType] = useState("LF");
    const [fftPlotData, setFFTPlotData] = useState({
        V1: { x: "", y: "" },
        V2: { x: "", y: "" },
        V3: { x: "", y: "" },
        I1: { x: "", y: "" },
        I2: { x: "", y: "" },
        I3: { x: "", y: "" },
        lf_type: "",
        Title: "",
    });
    const [showFFTPlot, setShowFFTPlot] = useState(false);
    const [isFFTPlotLoading, setIsFFTPlotLoading] = useState(false);

    const [showFFTAlert, setShowFFTAlert] = useState(false);
    const [fftAlertColor, setFFTAlertColor] = useState();
    const [fftalertText, setFFTAlertText] = useState();
    const [fftParamTableData, setFFTParamTableData] = useState({
        rows: [],
        columns: [{ dataField: "", text: "" }],
    });
    const [fftStartStopTableData, setFFTStartStopTableData] = useState({
        rows: [],
        columns: [{ dataField: "", text: "" }],
    });
    const [fftPlotFreqValue, setFFTPlotFreqValue] = useState("low_frequency");
    const [fftPlotMetaData, setFFTPlotMetaData] = useState({
        equipment_name: "",
        facility_name: "",
        esa_timestamp: "",
    });

    // Harmonic Amplitude Trending State Hooks
    const [selectedXAxisType, setSelectedXAxisType] = useState("Current");
    const [harmonicStartDate, setHarmonicStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    const [harmonicEndDate, setHarmonicEndDate] = useState(new Date());
    const [lfValue, setLFValue] = useState(0);
    const [harmonicPlotData, setHarmonicPlotData] = useState(null);

    const [isHarmonicPlotLoading, setIsHarmonicPlotLoading] = useState(false);
    const [showHarmonicPlot, setShowHarmonicPlot] = useState(false);
    const [isLFValueInvalid, setIsLFValueInvalid] = useState(false);
    const [showHarmonicAlert, setShowHarmonicAlert] = useState(false);
    const [harmonicAlertColor, setHarmonicAlertColor] = useState();
    const [harmonicAlertText, setHarmonicAlertText] = useState();
    const [harmonicSearchType, setHarmonicSearchType] = useState("LF");
    const [harmonicAveragePeriod, setHarmonicAveragePeriod] = useState("6 hour");
    const [harmonicInputRange, setHarmonicInputRange] = useState(200);
    const [harmonicPlotMetaData, setHarmonicPlotMetaData] = useState({
        equipmentName: "",
        facilityName: "",
        esa_timestamp: "",
    });
    const [plotFromFFTData, setPlotFromFFTData] = useState();

    const [selectedSubplotParameters, setSelectedSubplotParameters] = useState([]);
    //Setting plot parameters for AC vs DC
    useEffect(() => {
        if (selectedEquipment) {
            if (selectedEquipment.product_type == "Node") {
                if (selectedEquipment.eq_type === "dc") {
                    plotParameters = plotParametersDC;

                    //Add Field parameters for primary nodes
                    if (selectedEquipment.location_node_id.split(".")[1] == "0") {
                        plotParameters = plotParameters.concat(fieldParameters);
                    }
                } else {
                    plotParameters = plotParametersAC;

                    //Add field parameters for V2s
                    if (selectedEquipment.eq_type_sub === "v2") {
                        plotParameters = plotParameters.concat(fieldParameters);
                    }
                }

                if (selectedEquipment.equipment_type == "UtilityMain") {
                    plotParameters = plotParameters
                        .filter((params) => {
                            return params.label != "HP" && params.label != "Power Factor";
                        })
                        .concat(plotParametersUtilityMain);
                }
            } else if (selectedEquipment.product_type == "SEL") {
                plotParameters = plotParametersSEL;
            } else if (selectedEquipment.product_type == "GERelay") {
                plotParameters = plotParametersRelay;
            }

            //Checks to see if previously selected parameters are still avilable and removes those which aren't
            let params = selectedSubplotParameters.filter((param) => {
                if (plotParameters.some((el) => el.label === param.label)) {
                    return true;
                } else {
                    return false;
                }
            });
            setSelectedSubplotParameters(params);
        }
    }, [selectedEquipment]);

    useEffect(() => {
        // Api call for Esa list
        if (selectedEquipment) {
            setFFTEsaList([]);
            setselectedFFTEsa();

            // Api call for Esa list
            HarmonicAnalysisApi.get_fft_esa_list(
                token,
                selectedEquipment.value,
                formatDate(selectedFFTDate),
                equipmentTimezone.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setFFTAlertColor("danger");
                        setFFTAlertText("No ESA Found");
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setFFTAlertColor("primary");
                            setFFTAlertText(text);
                            setShowFFTAlert(true);
                        } else {
                            setFFTEsaList(response.data.content.list_data);
                            setShowFFTAlert(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [equipmentTimezone]);

    useEffect(() => {
        if (selectedFacility) {
            setShowFFTAlert(false);

            // Clear Esa Dropdown
            setFFTEsaList([]);
            setselectedFFTEsa();

            setShowFFTAlert(false);
            setShowHarmonicAlert(false);
        }
    }, [selectedFacility]);

    useEffect(() => {
        if (selectedEquipment) {
            setShowFFTAlert(false);
            setShowHarmonicAlert(false);

            // Clear Esa Dropdown
            setFFTEsaList([]);
            setselectedFFTEsa();

            // Api call for Esa list
            HarmonicAnalysisApi.get_fft_esa_list(
                token,
                selectedEquipment.value,
                formatDate(selectedFFTDate),
                equipmentTimezone.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setFFTAlertColor("danger");
                        setFFTAlertText("No ESA Found");
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setFFTAlertColor("primary");
                            setFFTAlertText(text);
                            setShowFFTAlert(true);
                        } else {
                            setFFTEsaList(response.data.content.list_data);
                            setShowFFTAlert(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [selectedEquipment]);

    useEffect(() => {
        if (showFFTPlot && selectedEquipment.product_type !== "SEL") {
            onPlotFFTButtonClick();
        }
        if (showHarmonicPlot && selectedXAxisType === "Voltage") {
            onHarmonicPlotButtonClick();
        }
    }, [selectedVoltageType]);

    useEffect(() => {
        if (showSELMenu && activeTabKey !== "Plot FFT" && activeTabKey !== "Harmonic Amplitude Trending") {
            setActiveTabKey("Plot FFT");
        }
    }, [showSELMenu]);

    {
        /* **************************************** Plot FFT Tab Functions **************************************** */
    }

    useEffect(() => {
        // effect trigger when props changed from fftt plot by click and it start ploting the harmonic plot
        if (plotFromFFTData) {
            plotHarmonicFromFFT(
                plotFromFFTData.selectedEquipment,
                plotFromFFTData.harmonic,
                plotFromFFTData.harmonicXAxisType,
                plotFromFFTData.harmonicType
            );
        }
    }, [plotFromFFTData]);

    // Function Triggers on select Date
    const onFFTDateChange = (date) => {
        setSelectedFFTDate(date);
        setFFTEsaList([]);
        setselectedFFTEsa();
        if (selectedEquipment) {
            // Api call for Esa list
            HarmonicAnalysisApi.get_fft_esa_list(
                token,
                selectedEquipment.value,
                formatDate(date),
                equipmentTimezone.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setFFTAlertColor("danger");
                        setFFTAlertText("No ESA Found");
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setFFTAlertColor("primary");
                            setFFTAlertText(text);
                            setShowFFTAlert(true);
                        } else {
                            setFFTEsaList(response.data.content.list_data);
                            setShowFFTAlert(false);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const onPlotFFTButtonClick = () => {
        if (!selectedFacility) {
            setFFTAlertColor("primary");
            setFFTAlertText("Select a Facility ");
            setShowFFTAlert(true);
        } else if (!selectedEquipment) {
            setFFTAlertColor("primary");
            setFFTAlertText("Select an Equipment ");
            setShowFFTAlert(true);
        } else if (!selectedFFTEsa) {
            setFFTAlertColor("primary");
            setFFTAlertText("Select a ESA File");
            setShowFFTAlert(true);
        } else {
            // setFFTParamTableData({
            //     columns: [{ dataField: "", text: "" }],
            //     rows: [
            //         {
            //             "": " ",
            //             Ia: 0,
            //             Ib: 0,
            //             Ic: 0,
            //             Vab: 0,
            //             Vbc: 0,
            //             Vca: 0,
            //         },
            //     ],
            // });
            // setFFTStartStopTableData({
            //     columns: [{ dataField: "", text: "" }],
            //     rows: [{ "": "", Count: 0 }],
            // });

            setShowFFTAlert(false);
            setIsFFTPlotLoading(true);
            setShowFFTPlot(true);

            setFFTPlotMetaData({
                selectedEquipment: selectedEquipment,
                esa_timestamp: selectedFFTEsa.label,
                selectedVoltageType: selectedVoltageType,
            });
            HarmonicAnalysisApi.getFFTData(
                token,
                selectedEquipment.value,
                selectedFFTEsa.value,
                fftPlotAxisType,
                fftPlotFreqValue,
                selectedVoltageType.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setIsFFTPlotLoading(false);
                        setFFTAlertColor("primary");
                        setFFTAlertText("No Data Found");
                        setShowFFTAlert(true);
                    } else {
                        // Remove unused channels for eq_type_sub v1
                        if (selectedEquipment.eq_type_sub === "v1") {
                            response.data.content.plot_data.V2.x = [];
                            response.data.content.plot_data.V2.x = [];

                            response.data.content.plot_data.V3.x = [];
                            response.data.content.plot_data.V3.y = [];
                        }
                        setFFTPlotData(response.data.content.plot_data);
                        setFFTParamTableData(response.data.content.table_data.parameter_data);
                        setFFTStartStopTableData(response.data.content.table_data.start_stop_data);
                        setShowFFTPlot(true);
                        setIsFFTPlotLoading(false);
                        setPlotOrder({
                            fft: 1,
                            harmonic: 2,
                        });
                    }
                })
                .catch((error) => {
                    setIsFFTPlotLoading(false);
                    setShowFFTPlot(false);
                    console.log(error);
                });
        }
    };

    {
        /* **************************************** Harmonic Amp Trending Tab Functions **************************************** */
    }

    const onHarmonicStartDateChange = (date) => {
        setHarmonicStartDate(date);
    };
    const onHarmonicEndDateChange = (date) => {
        setHarmonicEndDate(date);
    };

    useEffect(() => {
        let temp_var = 200;
        if (harmonicSearchType === "Hz") {
            setHarmonicInputRange(10000);
            temp_var = 10000;
        } else {
            setHarmonicInputRange(200);
            temp_var = 200;
        }

        if (lfValue > temp_var) setIsLFValueInvalid(true);
        else setIsLFValueInvalid(false);
    }, [harmonicSearchType]);

    const onLFCahnge = (value) => {
        const val = parseFloat(value);
        setLFValue(parseFloat(value));
        if (val > 0 && val <= harmonicInputRange) {
            setIsLFValueInvalid(false);
        } else {
            setIsLFValueInvalid(true);
        }
    };

    const onHarmonicPlotButtonClick = () => {
        if (!selectedFacility) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Select a Facility ");
            setShowHarmonicAlert(true);
        } else if (!selectedEquipment) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Select an Equipment");
            setShowHarmonicAlert(true);
        } else if (!(lfValue > 0 && lfValue <= harmonicInputRange)) {
            setHarmonicAlertColor("primary");
            setHarmonicAlertText("Enter LF Value");
            setShowHarmonicAlert(true);
        } else {
            setShowHarmonicAlert(false);
            setIsHarmonicPlotLoading(true);
            setShowHarmonicPlot(true);
            setHarmonicPlotMetaData({
                selectedEquipment: selectedEquipment,
                harmonicType: selectedXAxisType,
                harmonicLF: lfValue,
                searchType: harmonicSearchType,
                selectedVoltageType: selectedVoltageType,
                selectedParameters: selectedSubplotParameters,
            });
            if (selectedEquipment.product_type === "Node") {
                HarmonicAnalysisApi.getHarmonicPlotData(
                    token,
                    selectedEquipment.value,
                    formatDate(harmonicStartDate),
                    formatDate(harmonicEndDate),
                    lfValue,
                    selectedXAxisType,
                    harmonicSearchType,
                    harmonicAveragePeriod,
                    equipmentTimezone.value,
                    selectedVoltageType.value,
                    selectedSubplotParameters.map((param) => param.value),
                    selectedEquipment.work_cycle
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setHarmonicAlertColor("primary");
                            setHarmonicAlertText("No Data Found");
                            setShowHarmonicAlert(true);
                            setIsHarmonicPlotLoading(false);
                        } else {
                            setHarmonicPlotData(response.data.content);

                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                            setPlotOrder({
                                fft: 2,
                                harmonic: 1,
                            });
                        }
                    })
                    .catch((error) => {
                        setShowHarmonicAlert(false);
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            } else {
                HarmonicAnalysisApi.getSelHarmonicPlotData(
                    token,
                    selectedEquipment.value,
                    formatDate(harmonicStartDate),
                    formatDate(harmonicEndDate),
                    lfValue,
                    selectedXAxisType,
                    harmonicSearchType,
                    harmonicAveragePeriod,
                    equipmentTimezone.value,
                    selectedSubplotParameters.map((param) => param.value)
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setHarmonicAlertColor("primary");
                            setHarmonicAlertText("No Data Found");
                            setShowHarmonicAlert(true);
                            setIsHarmonicPlotLoading(false);
                        } else {
                            setHarmonicPlotData(response.data.content);

                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                            setPlotOrder({
                                fft: 2,
                                harmonic: 1,
                            });
                        }
                    })
                    .catch((error) => {
                        setShowHarmonicAlert(false);
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            }
        }
    };

    const plotHarmonicFromFFT = (selectedEquipmentInfo, harmonicLF, axisType, trendType) => {
        if (!isHarmonicPlotLoading) {
            setPlotOrder({
                fft: 2,
                harmonic: 1,
                hat: 3,
            });
            setIsHarmonicPlotLoading(true);
            setShowHarmonicAlert(false);

            const equipStartDate = new Date(parseInt(selectedEquipmentInfo.eqpt_start_epoch * 1000));
            let defaultDate = new Date(new Date().setMonth(new Date().getMonth() - 3));
            // set the date picker start date to 3 month back
            if (equipStartDate < defaultDate) {
                defaultDate = equipStartDate;
            }
            setHarmonicEndDate(new Date());
            setShowHarmonicPlot(true);
            setHarmonicPlotMetaData({
                selectedEquipment: selectedEquipmentInfo,
                harmonicType: axisType,
                harmonicLF: harmonicLF,
                searchType: trendType,
                selectedVoltageType: selectedVoltageType,
                isPlotLoading: true,
                selectedParameters: [],
            });

            setHarmonicSearchType(trendType);
            setLFValue(harmonicLF);
            setSelectedXAxisType(axisType);

            if (selectedEquipment.product_type === "Node") {
                HarmonicAnalysisApi.getHarmonicPlotData(
                    token,
                    selectedEquipmentInfo.value,
                    formatDate(defaultDate),
                    formatDate(harmonicEndDate),
                    harmonicLF,
                    axisType,
                    trendType,
                    harmonicAveragePeriod,
                    equipmentTimezone.value,
                    selectedVoltageType.value,
                    selectedSubplotParameters.map((param) => param.value),
                    selectedEquipment.work_cycle
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setHarmonicAlertColor("primary");
                            setHarmonicAlertText("No Data Found");
                            setShowHarmonicAlert(true);
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(false);
                        } else {
                            setHarmonicPlotData(response.data.content);
                            setLFValue(response.data.content.search_harmonic);
                            setHarmonicPlotMetaData({ ...harmonicPlotMetaData, isPlotLoading: false });
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                        }
                    })
                    .catch((error) => {
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            } else {
                HarmonicAnalysisApi.getSelHarmonicPlotData(
                    token,
                    selectedEquipmentInfo.value,
                    formatDate(defaultDate),
                    formatDate(harmonicEndDate),
                    harmonicLF,
                    axisType,
                    trendType,
                    harmonicAveragePeriod,
                    equipmentTimezone.value,
                    selectedSubplotParameters.map((param) => param.value)
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setHarmonicAlertColor("primary");
                            setHarmonicAlertText("No Data Found");
                            setShowHarmonicAlert(true);
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(false);
                        } else {
                            setHarmonicPlotData(response.data.content);
                            setLFValue(response.data.content.search_harmonic);
                            setHarmonicPlotMetaData({ ...harmonicPlotMetaData, isPlotLoading: false });
                            setIsHarmonicPlotLoading(false);
                            setShowHarmonicPlot(true);
                        }
                    })
                    .catch((error) => {
                        setIsHarmonicPlotLoading(false);
                        console.log(error);
                    });
            }
        }
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>
                        {/************************************************* Sub Menu Tabs **********************************************************************/}
                        <Col className="col-8">
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        defaultActiveKey="Plot FFT"
                                        activeKey={activeTabKey}
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            setActiveTabKey(tabName);
                                        }}
                                    >
                                        {/* **************************************** Plot FFT Tab **************************************** */}
                                        <Tab eventKey="Plot FFT" title="Plot FFT">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Plot FFT</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="harmonic-date">
                                                    <label>Select Date:</label>
                                                    <br></br>
                                                    <SingleDatePicker
                                                        startDate={selectedFFTDate}
                                                        onDateChange={onFFTDateChange}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label>Select X-Axis Type:</label>
                                                    <br></br>
                                                    <Col className="col-4 margin_left_-15">
                                                        <Form.Group as={Row}>
                                                            <Col sm={10}>
                                                                <Form.Check
                                                                    checked={fftPlotAxisType === "LF"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotAxisType(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    value="LF"
                                                                    label="LF"
                                                                />
                                                                <Form.Check
                                                                    checked={fftPlotAxisType === "Hz"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotAxisType(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Hz"
                                                                    value="Hz"
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col className="col-6">
                                                    <label>Select ESA File:</label>
                                                    <br></br>
                                                    <Dropdown
                                                        options={fftEsaList}
                                                        value={selectedFFTEsa}
                                                        onChange={(value) => {
                                                            setShowFFTAlert(false);
                                                            setselectedFFTEsa(value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label>Select FFT Range:</label>
                                                    <br></br>
                                                    <Col>
                                                        <Form.Group as={Row}>
                                                            <Col sm={10}>
                                                                <Form.Check
                                                                    checked={fftPlotFreqValue === "low_frequency"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotFreqValue(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Low Frequency"
                                                                    value="low_frequency"
                                                                />
                                                                <Form.Check
                                                                    checked={fftPlotFreqValue === "medium_frequency"}
                                                                    onChange={(val) =>
                                                                        setFFTPlotFreqValue(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Medium Frequency"
                                                                    value="medium_frequency"
                                                                />
                                                                {!showSELMenu ? (
                                                                    <Form.Check
                                                                        checked={fftPlotFreqValue === "high_frequency"}
                                                                        onChange={(val) =>
                                                                            setFFTPlotFreqValue(val.target.value)
                                                                        }
                                                                        type="radio"
                                                                        label="High Frequency"
                                                                        value="high_frequency"
                                                                    />
                                                                ) : null}
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-2">
                                                    {isFFTPlotLoading ? ( // TODO: Make plot loading async
                                                        <ReactLoading
                                                            type="bars"
                                                            height={50}
                                                            width={50}
                                                            color="#007bff"
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onPlotFFTButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col className="col-5 harmonicAlert alert">
                                                    <Alert
                                                        variant={fftAlertColor}
                                                        show={showFFTAlert}
                                                        onClose={() => setShowFFTAlert(false)}
                                                        dismissible
                                                    >
                                                        {fftalertText}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>

                                        {/* **************************************** Harmonic Amplitude Trending Tabs **************************************** */}
                                        <Tab eventKey="Harmonic Amplitude Trending" title="Harmonic Amplitude Trending">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Harmonic Amplitude Trending</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-7 harmonic-date">
                                                    <label>Select Date Range:</label>

                                                    <DateRangePicker
                                                        startDate={harmonicStartDate}
                                                        endDate={harmonicEndDate}
                                                        onStartDateChange={onHarmonicStartDateChange}
                                                        onEndDateChange={onHarmonicEndDateChange}
                                                    />
                                                </Col>
                                                <Col>
                                                    <label>Select X-Axis Type:</label>

                                                    <Col className="col-5">
                                                        <Form.Group as={Row}>
                                                            <Col sm={10} className="margin_left_-15">
                                                                <Form.Check
                                                                    checked={selectedXAxisType === "Current"}
                                                                    onChange={(val) =>
                                                                        setSelectedXAxisType(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="Current"
                                                                    value="Current"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    value="Voltage"
                                                                    checked={selectedXAxisType === "Voltage"}
                                                                    label="Voltage"
                                                                    onChange={(val) =>
                                                                        setSelectedXAxisType(val.target.value)
                                                                    }
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <br></br>
                                            <Row>
                                                <Col className="col-7">
                                                    <label>Trend Harmonic:</label>
                                                    <Form.Group inline>
                                                        <Form.Check
                                                            inline
                                                            checked={harmonicSearchType === "LF"}
                                                            onChange={(val) => {
                                                                setHarmonicSearchType(val.target.value);
                                                            }}
                                                            type="radio"
                                                            label="LF"
                                                            value="LF"
                                                        />
                                                        <Form.Check
                                                            inline
                                                            type="radio"
                                                            value="Hz"
                                                            checked={harmonicSearchType === "Hz"}
                                                            label="Hz"
                                                            onChange={(val) => {
                                                                setHarmonicSearchType(val.target.value);
                                                            }}
                                                        />
                                                    </Form.Group>
                                                    <Row>
                                                        <Col className="col-8">
                                                            <Form.Control
                                                                type="number"
                                                                min={0}
                                                                max={harmonicInputRange}
                                                                value={lfValue}
                                                                onInput={(newLF) => onLFCahnge(newLF.target.value)}
                                                                isInvalid={isLFValueInvalid}
                                                                required
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col>
                                                    <label>Average Period:</label>
                                                    <Col className="col-5">
                                                        <Form.Group as={Row}>
                                                            <Col sm={12} className="margin_left_-15">
                                                                <Form.Check
                                                                    checked={harmonicAveragePeriod === "6 hour"}
                                                                    onChange={(val) =>
                                                                        setHarmonicAveragePeriod(val.target.value)
                                                                    }
                                                                    type="radio"
                                                                    label="6 hours"
                                                                    value="6 hour"
                                                                />
                                                                <Form.Check
                                                                    type="radio"
                                                                    value="15 min"
                                                                    checked={harmonicAveragePeriod === "15 min"}
                                                                    label="None"
                                                                    onChange={(val) =>
                                                                        setHarmonicAveragePeriod(val.target.value)
                                                                    }
                                                                />
                                                            </Col>
                                                        </Form.Group>
                                                    </Col>
                                                </Col>
                                            </Row>
                                            <Row className="mt-3">
                                                <Col>
                                                    <p>Add Subplots:</p>
                                                    <Select
                                                        isMulti
                                                        isClearable
                                                        defaultValue={[]}
                                                        value={selectedSubplotParameters}
                                                        onChange={(value) => setSelectedSubplotParameters(value)}
                                                        options={plotParameters}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-2">
                                                    {isHarmonicPlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            color="#007bff"
                                                            height={50}
                                                            width={50}
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onHarmonicPlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}{" "}
                                                </Col>
                                                <Col className="col-5 harmonicAlert alert">
                                                    <Alert
                                                        variant={harmonicAlertColor}
                                                        show={showHarmonicAlert}
                                                        onClose={() => setShowHarmonicAlert(false)}
                                                        dismissible
                                                    >
                                                        {harmonicAlertText}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            {/********************************************** Plot Section ***************************************************************************/}
            <Suspense fallback={<div>Loading..</div>}>
                <Row>
                    <Col lg={{ order: plotOrder.fft, span: 12 }}>
                        {showFFTPlot ? (
                            <FFTPlot
                                fftPlotData={fftPlotData}
                                paramsTableData={fftParamTableData}
                                startStopTableData={fftStartStopTableData}
                                setFFTPlotData={setFFTPlotData}
                                showPlot={showFFTPlot}
                                setShowPlot={setShowFFTPlot}
                                plotMetaData={fftPlotMetaData}
                                setPlotFromFFTData={setPlotFromFFTData}
                                isPlotLoading={isFFTPlotLoading}
                            />
                        ) : null}
                    </Col>
                    <Col lg={{ order: plotOrder.harmonic, span: 12 }}>
                        {showHarmonicPlot ? (
                            <HarmonicAmplitudeTrendingPlot
                                harmonicPlotData={harmonicPlotData}
                                setHarmonicPlotData={setHarmonicPlotData}
                                showPlot={showHarmonicPlot}
                                setShowPlot={setShowHarmonicPlot}
                                plotMetaData={harmonicPlotMetaData}
                                isPlotLoading={isHarmonicPlotLoading}
                            />
                        ) : null}
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
