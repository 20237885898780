import React, { useState, useEffect, Suspense, lazy } from "react";
import { useSelector } from "react-redux";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";

import DateRangePicker from "../DatePickers/DateRangePicker/DateRangePicker";
import EventsSortingTable from "../EventsSortingTable";
import LeftFilterBox from "../LeftFilterBox/LeftFilterBox";
import DateTimePicker from "../DatePickers/DateTimePicker/DateTimePicker";
import EventAnalysisAPI from "../../api/EventsAnalysisApi";

import Dropdown from "react-dropdown";
import Cookies from "js-cookie";
import ReactLoading from "react-loading";

const EventsCapturePlot = lazy(() => import("../plots/EventsCapturePlot"));
const CorrelatingEventTable = lazy(() => import("../plots/CorrelatingEventsTabel"));
const EventsTrendingPlot = lazy(() => import("../plots/EventsTrendingPlot"));
const SELTrendingPlot = lazy(() => import("../plots/SELTrendingPlot"));

const selectedFreqCalculationType = "No Frequency Calculation";

const formatDate = (date) => {
    return date.getFullYear() + "-" + (date.getMonth() + 1) + "-" + date.getDate();
};

const formatDateTime = (date) => {
    return (
        date.getFullYear() +
        "-" +
        (date.getMonth() + 1) +
        "-" +
        date.getDate() +
        " " +
        date.toTimeString().split(" ")[0]
    );
};

export default function EventsAnalysisTab() {
    const token = Cookies.get("Session");

    // extarcting from redux slice
    const { selectedFacility, selectedEquipment, equipmentTimezone, showSELMenu, selectedCustomer } = useSelector(
        (state) => state.equipmentsPortfolio
    );
    const selectedVoltageType = useSelector((state) => state.voltageType.selectedVoltageType);
    const [activeTabKey, setActiveTabKey] = useState("Events Capture");

    const [plotOrder, setPlotOrder] = useState({
        eventsCapture: 1,
        correlatingEvents: 2,
        eventsTrending: 3,
        selTrending: 4,
    });

    // Events Sorting Table Hook
    const [eventSortingTableData, setEventsSortingTableData] = useState({ columns: "", rows: "" });

    // Events Capture Tab Hooks
    const [eventCaptureStartDate, setEventCaptureStartDate] = useState(
        new Date(new Date().valueOf() - 1000 * 60 * 60 * 24 * 7)
    );
    const [eventCaptureEndDate, setEventCaptureEndDate] = useState(new Date());

    const [eventFileList, setEventFileList] = useState([]);
    const [selectEventFile, setSelectdEventFile] = useState();
    const [eventCapturePlotData, setEventCapturePlotData] = useState({
        va: "",
        vb: "",
        vc: "",
        ia: "",
        ib: "",
        ic: "",
        np_I_peak: { x: "", y: "" },
        time: "",
        title: "",
    });

    const [eventsCaptureAlert, setEventsCaptureAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    const [isEventsCapturePlotLoading, setIsEventsCapturePlotLoading] = useState(false);
    const [showEventsCapturePlot, setShowEventsCapturePlot] = useState(false);
    const [eventCapturePlotMetaData, setEventCapturePlotMetaData] = useState({
        equipment_name: "",
        eqTypeSub: "---",
        facility_name: "",
        esa_timestamp: "",
        analysisType: "None",
        Np_I_peak: "",
    });

    // Correlating Event Tab Hooks
    const [isCorrelatingTableLoading, setIsCorrelatingTableLoading] = useState(false);

    const [correlatingAlert, setCorrelatingAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    const [correlatingEventsDateTime, setCorrelatingEventsDateTime] = useState(new Date());

    const [correlatingEventsSeconds, setCorrelatingEventsSeconds] = useState(2);
    const [isCorrelatingDurationInValid, setIsCorrelatingDurationInValid] = useState(false);
    const [showCorrelatingTable, setShowCorrelatingTable] = useState(false);
    useEffect(() => {
        setCorrelatingAlert({ ...correlatingAlert, show: false });
    }, [selectedCustomer, selectedFacility, selectedEquipment]);

    const [CorrelatingTabData, setCorrelatingTabData] = useState({
        tableData: { rows: "", columns: "" },
        plotMetaData: "",
        showPlot: false,
        isCorrelatingPlotLoading: false,
        currentSelectedTimeOnEventsCaptureTab: 0,
    });

    useEffect(() => {
        if (selectEventFile) {
            const inputDateString = selectEventFile.label;
            const parts = inputDateString.split(/[- :]/);
            const year = parseInt(parts[0], 10);
            const month = parseInt(parts[1], 10) - 1;
            const day = parseInt(parts[2], 10);
            const hour = parseInt(parts[3], 10);
            const minute = parseInt(parts[4], 10);
            const second = parseInt(parts[5], 10);
            const outputDate = new Date(year, month, day, hour, minute, second);
            setCorrelatingEventsDateTime(outputDate);
            return;
        }
    }, [selectEventFile]);

    // Events Trending Tab Hooks
    const [eventsTrendingStartDate, setEventsTrendingStartDate] = useState(
        new Date(new Date().setMonth(new Date().getMonth() - 1))
    );
    const [eventsTrendingEndDate, setEventsTrendingEndDate] = useState(new Date());
    const [eventsTrendingPlotData, setEventsTrendingPlotData] = useState({
        current_rise: [],
        current_fall: [],
        voltage_rise: [],
        voltage_fall: [],
        start: [],
        stop: [],
        start_d: [],
        stop_u: [],
        day: [],
        Title: [],
    });

    const [isEventsTrendingPlotLoading, setIsEventsTrendingPlotLoading] = useState(false);
    const [showEventsTrendingPlot, setShowEventsTrendingPlot] = useState(false);

    const [eventsTrendingAlert, setEventsTrendingAlert] = useState({
        color: "blue",
        text: "",
        show: false,
    });

    const [eventsTrendingPlotMetaData, setEventsTrendingPlotMetaData] = useState({
        equipment_name: "",
        facility_name: "",
        startDate: "",
        endDate: "",
    });
    const [selTrendingPlotData, setSelTrendingPlotData] = useState({
        plotData: [],
        showPlot: false,
        plotMetaData: [],
    });

    useEffect(() => {
        if (selectedFacility) {
            setEventFileList([]);
            setSelectdEventFile();
            setEventsCaptureAlert({ ...eventsCaptureAlert, show: false });
            setCorrelatingAlert({ ...correlatingAlert, show: false });
            setEventsTrendingAlert({ ...eventsTrendingAlert, show: false });
            setEventsSortingTableData({ columns: "", rows: "" });
        }
    }, [selectedFacility]);

    useEffect(() => {
        if (selectedEquipment) {
            setEventFileList([]);
            setSelectdEventFile();
            setEventsCaptureAlert({ ...eventsCaptureAlert, show: false });
            setCorrelatingAlert({ ...correlatingAlert, show: false });
            setEventsTrendingAlert({ ...eventsTrendingAlert, show: false });
            setEventsSortingTableData({ columns: "", rows: "" });

            EventAnalysisAPI.getEventsList(
                token,
                selectedEquipment.value,
                selectedEquipment.label,
                formatDate(eventCaptureStartDate),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "danger",
                            text: "No Event Found",
                            show: true,
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setEventsSortingTableData(response.data.content.table_data);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
            if (selectedEquipment.product_type === "SEL") {
                if (activeTabKey !== "Events Capture" && activeTabKey !== "Events Trending") {
                    setActiveTabKey("Events Capture");
                }
            }
        } else {
            setEventFileList([]);
            setEventsSortingTableData({ columns: "", rows: "" });
        }
    }, [selectedEquipment]);

    useEffect(() => {
        if (showEventsCapturePlot) {
            setEventCapturePlotMetaData({ ...eventCapturePlotMetaData, selectedVoltageType: selectedVoltageType });
        }
        if (selectedEquipment) {
            EventAnalysisAPI.getEventsList(
                token,
                selectedEquipment.value,
                selectedEquipment.label,
                formatDate(eventCaptureStartDate),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "danger",
                            text: "No Event Found",
                            show: true,
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setEventsSortingTableData(response.data.content.table_data);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [selectedVoltageType]);

    {
        /************************************ Event Capture Tab fuctions **************************************************************/
    }

    useEffect(() => {
        setEventsCaptureAlert({
            color: "danger",
            text: "",
            show: false,
        });
        if (selectedEquipment) {
            setSelectdEventFile();
            setEventFileList([]);
            setEventsSortingTableData({ columns: "", rows: "" });

            EventAnalysisAPI.getEventsList(
                token,
                selectedEquipment.value,
                selectedEquipment.label,
                formatDate(eventCaptureStartDate),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "danger",
                            text: "No Event Found",
                            show: true,
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setEventsSortingTableData(response.data.content.table_data);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    }, [equipmentTimezone, selectedVoltageType]);

    const onEventCaptureStartDateChange = (date) => {
        setEventFileList([]);
        setSelectdEventFile();
        setEventCaptureStartDate(date);
        setEventsSortingTableData({ columns: "", rows: "" });
        setEventsCaptureAlert({
            color: "danger",
            text: "",
            show: false,
        });

        if (selectedEquipment) {
            EventAnalysisAPI.getEventsList(
                token,
                selectedEquipment.value,
                selectedEquipment.label,
                formatDate(date),
                formatDate(eventCaptureEndDate),
                equipmentTimezone.value,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "danger",
                            text: "No Event Found",
                            show: true,
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            if (response.data.content == null) {
                                const text = response.data.info;
                                setEventsCaptureAlert({
                                    color: "primary",
                                    text: text,
                                    show: true,
                                });
                            } else {
                                setEventFileList(response.data.content.list_data);
                                setEventsSortingTableData(response.data.content.table_data);
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const onEventCaptureEndDateChange = (date) => {
        setEventFileList([]);
        setSelectdEventFile();
        setEventsSortingTableData({ columns: "", rows: "" });

        setEventsCaptureAlert({
            color: "danger",
            text: "No Event Found",
            show: false,
        });
        setEventCaptureEndDate(date);
        if (selectedEquipment) {
            EventAnalysisAPI.getEventsList(
                token,
                selectedEquipment.value,
                selectedEquipment.label,
                formatDate(eventCaptureStartDate),
                formatDate(date),
                equipmentTimezone.value,
                selectedEquipment.product_type,
                selectedVoltageType.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "danger",
                            text: "No Event Found",
                            show: true,
                        });
                    } else {
                        if (response.data.content == null) {
                            const text = response.data.info;
                            setEventsCaptureAlert({
                                color: "primary",
                                text: text,
                                show: true,
                            });
                        } else {
                            setEventFileList(response.data.content.list_data);
                            setEventsSortingTableData(response.data.content.table_data);
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                });
        }
    };

    const onEventCapturePlotButtonClick = () => {
        if (!selectedFacility) {
            setEventsCaptureAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setEventsCaptureAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else if (!selectEventFile) {
            setEventsCaptureAlert({
                color: "primary",
                text: "Select Event File",
                show: true,
            });
        } else {
            setShowEventsCapturePlot(true);
            setEventsCaptureAlert({ ...eventsCaptureAlert, show: false });
            setIsEventsCapturePlotLoading(true);
            setEventCapturePlotMetaData({
                selectedEquipment: selectedEquipment,
                selectedESA: selectEventFile,
                analysisType: selectedFreqCalculationType.value,
                selectedVoltageType: selectedVoltageType,
            });
            setEventCapturePlotData({
                time: [],
                current: {
                    Ia: [],
                    Ib: [],
                    Ic: [],
                },
                voltage_ln: {
                    Va: [],
                    Vb: [],
                    Vc: [],
                },
            });

            EventAnalysisAPI.getEventsPlotData(
                token,
                selectedEquipment.location_node_id,
                selectEventFile.value,
                selectedEquipment.product_type
            )
                .then((response) => {
                    if (response.status === 204) {
                        setEventsCaptureAlert({
                            color: "primary",
                            text: "No Data Found",
                            show: true,
                        });
                    } else {
                        setEventCapturePlotData(response.data.content.plot_data);
                        setIsEventsCapturePlotLoading(false);
                        setShowEventsCapturePlot(true);
                        setPlotOrder({
                            eventsCapture: 1,
                            correlatingEvents: 2,
                            eventsTrending: 3,
                            selTrending: 4,
                        });
                    }
                })
                .catch((err) => {
                    setIsEventsCapturePlotLoading(false);
                    setShowEventsCapturePlot(false);
                    console.log(err);
                });
        }
    };

    {
        /************************************ Correlating Tab fuctions **************************************************************/
    }
    //Close tab
    useEffect(() => {
        if (!CorrelatingTabData.showPlot) {
            setShowCorrelatingTable(false);
        }
    }, [CorrelatingTabData]);

    const onCorrelatingDateTimeChange = (date) => {
        setCorrelatingEventsDateTime(date);
    };

    const onCorrelatingSecondsChange = (val) => {
        setCorrelatingEventsSeconds(val);
        if (val <= 0) {
            setIsCorrelatingDurationInValid(true);
        } else {
            setIsCorrelatingDurationInValid(false);
        }
    };
    const onCorrelatingPlotButtonClick = () => {
        // Hiding the banner
        setCorrelatingAlert({
            text: "No Events Found",
            show: false,
            color: "primary",
        });
        // Hiding the correlating Table
        setShowCorrelatingTable(false);

        if (!selectedCustomer) {
            setCorrelatingAlert({
                color: "primary",
                show: true,
                text: "Select a Customer",
            });
        } else if (!selectedFacility) {
            setCorrelatingAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else {
            setIsCorrelatingTableLoading(true);
            setShowCorrelatingTable(true);
            setCorrelatingTabData((prevState) => {
                return {
                    ...prevState,
                    tabelData: { rows: [], columns: [] },
                    tableData: { columns: [], rows: [], metadata: {} },
                    tableMetaData: { selectedFacility: selectedFacility },
                    showTable: true,
                };
            });

            EventAnalysisAPI.get_correlating_events_list(
                token,
                selectedFacility.facility_id,
                formatDateTime(correlatingEventsDateTime),
                correlatingEventsSeconds,
                equipmentTimezone.value
            )
                .then((response) => {
                    if (response.status === 204) {
                        setCorrelatingAlert({
                            text: "No Events Found",
                            show: true,
                            color: "primary",
                        });
                        setIsCorrelatingTableLoading(false);
                        setCorrelatingTabData((prevState) => {
                            return {
                                ...prevState,
                                showTable: false,
                                isCorrelatingTableLoading: false,
                            };
                        });
                    } else {
                        setCorrelatingTabData((prevState) => {
                            return {
                                ...prevState,
                                tableData: response.data.content,
                                isCorrelatingTableLoading: false,
                                showTable: true,
                            };
                        });
                        setIsCorrelatingTableLoading(false);
                        setShowCorrelatingTable(true);
                    }
                })
                .catch((err) => {
                    setIsCorrelatingTableLoading(false);
                    setShowCorrelatingTable(false);
                    setCorrelatingTabData((prevState) => {
                        return {
                            ...prevState,
                            isCorrelatingTableLoading: false,
                            showTable: false,
                        };
                    });
                    console.log(err);
                });
        }
    };
    {
        /************************************ Events Trending Tab fuctions **************************************************************/
    }

    const onEventsTrendingStartDateChange = (date) => {
        setEventsTrendingStartDate(date);
    };

    const onEventsTrendingEndDateChange = (date) => {
        setEventsTrendingEndDate(date);
    };

    const onEventsTrendingPlotButtonClick = () => {
        if (!selectedFacility) {
            setEventsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select a Facility",
            });
        } else if (!selectedEquipment) {
            setEventsTrendingAlert({
                color: "primary",
                show: true,
                text: "Select an Equipment ",
            });
        } else {
            setEventsTrendingAlert({ ...eventsTrendingAlert, show: false });
            setIsEventsTrendingPlotLoading(true);
            setEventsTrendingPlotMetaData({
                selectedEquipment: selectedEquipment,
                equipmentName: selectedEquipment.label,
                facilityName: selectedFacility.label,
                startDate: formatDate(eventsTrendingStartDate),
                endDate: formatDate(eventsTrendingEndDate),
            });
            setShowEventsTrendingPlot(true);

            if (selectedEquipment.product_type === "SEL") {
                setSelTrendingPlotData((prevState) => {
                    return {
                        ...prevState,
                        showPlot: true,
                        plotMetaData: {
                            selectedEquipment: selectedEquipment,
                            equipmentName: selectedEquipment.label,
                            facilityName: selectedFacility.label,
                            startDate: formatDate(eventsTrendingStartDate),
                            endDate: formatDate(eventsTrendingEndDate),
                        },
                    };
                });
                EventAnalysisAPI.getSELAlarmsTrip(
                    token,
                    selectedEquipment.value,
                    formatDate(eventsTrendingStartDate),
                    formatDate(eventsTrendingEndDate),
                    equipmentTimezone.value
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setEventsTrendingAlert({
                                color: "primary",
                                show: true,
                                text: "No Data Found",
                            });
                        } else {
                            setSelTrendingPlotData((prevState) => {
                                return {
                                    ...prevState,
                                    plotData: response.data.content.plot_data,
                                    showPlot: true,
                                };
                            });
                        }
                        setIsEventsTrendingPlotLoading(false);
                        setPlotOrder({
                            eventsCapture: 4,
                            correlatingEvents: 2,
                            eventsTrending: 2,
                            selTrending: 1,
                        });
                    })
                    .catch((err) => {
                        console.log(err);
                        setIsEventsTrendingPlotLoading(false);
                    });
            } else {
                EventAnalysisAPI.getEventsTrendingPlotData(
                    token,

                    selectedEquipment.value,
                    formatDate(eventsTrendingStartDate),
                    formatDate(eventsTrendingEndDate),
                    equipmentTimezone.value
                )
                    .then((response) => {
                        if (response.status === 204) {
                            setEventsTrendingAlert({
                                color: "primary",
                                show: true,
                                text: "No Data Found",
                            });
                        } else {
                            setEventsTrendingPlotData(response.data.content.plot_data.events_trending);
                            setIsEventsTrendingPlotLoading(false);
                            setPlotOrder({
                                eventsCapture: 3,
                                correlatingEvents: 2,
                                eventsTrending: 1,
                                selTrending: 4,
                            });
                        }
                    })
                    .catch((err) => {
                        setIsEventsTrendingPlotLoading(false);
                        setShowEventsTrendingPlot(false);
                        console.log(err);
                    });
            }
        }
    };

    return (
        <div>
            <Card>
                <Card.Body>
                    <Row className="mt-3">
                        {/************************************ Facility and Node Selection Column **************************************************************/}
                        <Col className="col-4">
                            <LeftFilterBox />
                        </Col>

                        {/********************************************************* Sub Menu Tabs ******************************************************************/}
                        <Col className="col-8 ">
                            <Card>
                                <Card.Body>
                                    <Tabs
                                        defaultActiveKey="Events Capture"
                                        activeKey={activeTabKey}
                                        variant="pills"
                                        onSelect={(tabName) => {
                                            setActiveTabKey(tabName);
                                        }}
                                    >
                                        {/******************************************** Events Capture Tab ********************************************************************/}
                                        <Tab eventKey="Events Capture" title="Events Capture">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Events Capture</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="event-data col-7">
                                                    <label>Select Date Range:</label>
                                                    <br></br>
                                                    <DateRangePicker
                                                        startDate={eventCaptureStartDate}
                                                        endDate={eventCaptureEndDate}
                                                        onStartDateChange={onEventCaptureStartDateChange}
                                                        onEndDateChange={onEventCaptureEndDateChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <br />
                                            <Row>
                                                <Col className="col-7">
                                                    {showSELMenu ? <br></br> : null}
                                                    <label>Select Event File:</label>
                                                    <br></br>
                                                    <Dropdown
                                                        options={eventFileList}
                                                        value={selectEventFile}
                                                        onChange={(value) => {
                                                            setEventsCaptureAlert({
                                                                color: "primary",
                                                                show: false,
                                                                text: "Select an Equipment ",
                                                            });
                                                            setSelectdEventFile(value);
                                                        }}
                                                    />
                                                    <br></br>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-2">
                                                    {isEventsCapturePlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            height={50}
                                                            width={50}
                                                            color="#007bff"
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onEventCapturePlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col className="col-4 eventsAlert">
                                                    <Alert
                                                        variant={eventsCaptureAlert.color}
                                                        show={eventsCaptureAlert.show}
                                                        onClose={() =>
                                                            setEventsCaptureAlert({
                                                                ...eventsCaptureAlert,
                                                                show: false,
                                                            })
                                                        }
                                                        dismissible
                                                    >
                                                        {eventsCaptureAlert.text}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>
                                        {/******************************************** Correlating Tab ********************************************************************/}
                                        <Tab
                                            eventKey="Correlating Events"
                                            title="Correlating Events"
                                            tabClassName={showSELMenu ? "d-none" : ""}
                                        >
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Correlating Events</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-5 event-data oneSec-date-input">
                                                    <label>Select Time:</label>
                                                    <br></br>
                                                    <DateTimePicker
                                                        selectedDate={correlatingEventsDateTime}
                                                        onDateTimeChange={onCorrelatingDateTimeChange}
                                                    />
                                                </Col>
                                                <Col className="col-3">
                                                    <label>Tolerance in seconds:</label>
                                                    <br></br>
                                                    <Form.Control
                                                        type="number"
                                                        min="0"
                                                        max="400000"
                                                        value={correlatingEventsSeconds}
                                                        onChange={(newDuration) =>
                                                            onCorrelatingSecondsChange(newDuration.target.value)
                                                        }
                                                        isInvalid={isCorrelatingDurationInValid}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col className="col-2">
                                                    <br></br>
                                                    {isCorrelatingTableLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            height={50}
                                                            width={50}
                                                            color="#007bff"
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onCorrelatingPlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col className="col-4 eventsAlert">
                                                    <br />
                                                    <Alert
                                                        variant={correlatingAlert.color}
                                                        show={correlatingAlert.show}
                                                        onClose={() =>
                                                            setCorrelatingAlert({ ...correlatingAlert, show: false })
                                                        }
                                                        dismissible
                                                    >
                                                        {correlatingAlert.text}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>

                                        {/******************************************** Events Trending Tab *****************************************************************/}
                                        <Tab eventKey="Events Trending" title="Events Trending">
                                            <Row>
                                                <Col>
                                                    <h2 className="margin-top-10">Events Trending</h2>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-7 event-data">
                                                    <label>Select Date Range:</label>
                                                    <br></br>
                                                    <DateRangePicker
                                                        startDate={eventsTrendingStartDate}
                                                        endDate={eventsTrendingEndDate}
                                                        onStartDateChange={onEventsTrendingStartDateChange}
                                                        onEndDateChange={onEventsTrendingEndDateChange}
                                                    />
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col className="col-2">
                                                    <br></br>
                                                    {isEventsTrendingPlotLoading ? (
                                                        <ReactLoading
                                                            type="bars"
                                                            height={50}
                                                            width={50}
                                                            color="#007bff"
                                                        />
                                                    ) : (
                                                        <Button
                                                            variant="primary"
                                                            onClick={onEventsTrendingPlotButtonClick}
                                                            className="plot_button"
                                                        >
                                                            Plot
                                                        </Button>
                                                    )}
                                                </Col>
                                                <Col className="col-4 eventsAlert">
                                                    <br />
                                                    <Alert
                                                        variant={eventsTrendingAlert.color}
                                                        show={eventsTrendingAlert.show}
                                                        onClose={() =>
                                                            setEventsTrendingAlert({
                                                                ...eventsTrendingAlert,
                                                                show: false,
                                                            })
                                                        }
                                                        dismissible
                                                    >
                                                        {eventsTrendingAlert.text}
                                                    </Alert>
                                                </Col>
                                            </Row>
                                        </Tab>
                                    </Tabs>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Card.Body>
            </Card>
            <br></br>
            <Suspense fallback={<div>Loading..</div>}>
                <EventsSortingTable
                    selectedEquipment={selectedEquipment}
                    setEventFile={setSelectdEventFile}
                    tableData={eventSortingTableData}
                />
                <br></br>
            </Suspense>
            <Suspense fallback={<div>Loading..</div>}>
                <Row>
                    <Col lg={{ order: plotOrder.eventsCapture, span: 12 }}>
                        {showEventsCapturePlot ? (
                            <EventsCapturePlot
                                plotData={eventCapturePlotData}
                                setEventCapturePlotData={setEventCapturePlotData}
                                showPlot={showEventsCapturePlot}
                                setShowPlot={setShowEventsCapturePlot}
                                plotMetaData={eventCapturePlotMetaData}
                                isPlotLoading={isEventsCapturePlotLoading}
                            />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotOrder.correlatingEvents, span: 12 }}>
                        {showCorrelatingTable ? (
                            <CorrelatingEventTable tabData={CorrelatingTabData} setTabData={setCorrelatingTabData} />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotOrder.eventsTrending, span: 12 }}>
                        {showEventsTrendingPlot ? (
                            <EventsTrendingPlot
                                eventsTrendingPlotData={eventsTrendingPlotData}
                                setEventsTrendingPlotData={setEventsTrendingPlotData}
                                showPlot={showEventsTrendingPlot}
                                setShowPlot={setShowEventsTrendingPlot}
                                plotMetaData={eventsTrendingPlotMetaData}
                                isPlotLoading={isEventsTrendingPlotLoading}
                            />
                        ) : null}
                    </Col>

                    <Col lg={{ order: plotOrder.selTrending, span: 12 }}>
                        {selTrendingPlotData.showPlot ? (
                            <SELTrendingPlot tabData={selTrendingPlotData} setTabData={setSelTrendingPlotData} />
                        ) : null}
                    </Col>
                </Row>
            </Suspense>
        </div>
    );
}
